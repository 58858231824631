import React from 'react'
import GallerySection from '../../component/room/GallerySection';
import DetailPropertyPage from '../../component/room/DetailPropertyPage';

const Host = () => {
  return (
    <>
      <GallerySection />
      <DetailPropertyPage />
    </>
  );
};

export default Host