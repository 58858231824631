import React, { useState } from 'react';
import { Container, Grid, Typography, IconButton, Collapse, Box } from '@mui/material';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems = [
    { question: 'How to list my place on Homey?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'How the payments work?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'How to upgrade my place to featured?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'How to get a refund?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'Who can become a host?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'How to get in touch with my guests?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'How to get paid?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
    { question: 'How to cancel a reservation?', answer: 'Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.' },
  ];

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h2" align="center" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {faqItems.slice(0, 4).map((faq, index) => (
            <Box key={index} sx={{ mb: 2, border: '1px solid #ccc', borderRadius: 1 }}>
              <Box sx={{ p: 2, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleToggle(index)}>
                <Typography variant="h6" flex={1}>{faq.question}</Typography>
                <IconButton>
                  {openIndex === index ? <MdExpandLess /> : <MdExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openIndex === index}>
                <Box sx={{ p: 2 }}>
                  <Typography>{faq.answer}</Typography>
                </Box>
              </Collapse>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          {faqItems.slice(4).map((faq, index) => (
            <Box key={index + 4} sx={{ mb: 2, border: '1px solid #ccc', borderRadius: 1 }}>
              <Box sx={{ p: 2, display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleToggle(index + 4)}>
                <Typography variant="h6" flex={1}>{faq.question}</Typography>
                <IconButton>
                  {openIndex === index + 4 ? <MdExpandLess /> : <MdExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openIndex === index + 4}>
                <Box sx={{ p: 2 }}>
                  <Typography>{faq.answer}</Typography>
                </Box>
              </Collapse>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQs;
