import { Grid, } from "@mui/material";
import StayCard from "./StayCard";

const Stays = () => {
  
  const properties = [
    {
      id: 1,
      images:  "./img/5.jpg",
      host: "Jon Snow",
      email: "jonsnow@gmail.com",
      age: 35,
      phone: "(665)121-5454",
      address: "0912 Won Street, Alabama, SY 10001",
      date_range: "New York",
      cost: "10001",
      registrarId: 123512,
    },
    {
      id: 2,
      images: "./img/3.jpg",
      host: "Cersei Lannister",
      email: "cerseilannister@gmail.com",
      age: 42,
      phone: "(421)314-2288",
      address: "1234 Main Street, New York, NY 10001",
      date_range: "New York",
      cost: "13151",
      registrarId: 123512,
    },
    {
      id: 3,
      images:  "./img/29.jpg",
      host: "Jaime Lannister",
      email: "jaimelannister@gmail.com",
      age: 45,
      phone: "(422)982-6739",
      address: "3333 Want Blvd, Estanza, NAY 42125",
      date_range: "New York",
      cost: "87281",
      registrarId: 4132513,
    },
    {
      id: 4,
      images: "./img/4.jpg",
      host: "Anya Stark",
      email: "anyastark@gmail.com",
      age: 16,
      phone: "(921)425-6742",
      address: "1514 Main Street, New York, NY 22298",
      date_range: "New York",
      cost: "15551",
      registrarId: 123512,
    },
    {
      id: 5,
      images:  "./img/38.jpg",
      host: "Daenerys Targaryen",
      email: "daenerystargaryen@gmail.com",
      age: 31,
      phone: "(421)445-1189",
      address: "11122 Welping Ave, Tenting, CD 21321",
      date_range: "Tenting",
      cost: "14215",
      registrarId: 123512,
    },
    {
      id: 6,
      images:  "./img/36.jpg",
      host: "Ever Melisandre",
      email: "evermelisandre@gmail.com",
      age: 150,
      phone: "(232)545-6483",
      address: "1234 Canvile Street, Esvazark, NY 10001",
      date_range: "Esvazark",
      cost: "10001",
      registrarId: 123512,
    },
    {
      id: 7,
      images:  "./img/30.jpg",
      host: "Ferrara Clifford",
      email: "ferraraclifford@gmail.com",
      age: 44,
      phone: "(543)124-0123",
      address: "22215 Super Street, Everting, ZO 515234",
      date_range: "Evertin",
      cost: "51523",
      registrarId: 123512,
    },
    {
      id: 8,
      images:  "./img/24.jpg",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    }
  ];

  return (
    <Grid container spacing={2}>
      {properties.map((property) => (
        <Grid item xs={12} sm={6} md={3} lg={3} key={property.id}>
          <StayCard property={property} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Stays;
