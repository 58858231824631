import React from 'react';
import {
  Box,
  Container,
  Typography
} from '@mui/material';
import SearchBar from './search/SearchBar';
const TopBanner = () => {

  return (
    <Box
      className="top-banner-wrap"
      sx={{
        backgroundImage: "url('/img/hotel1.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        py: 10,
        textAlign: 'center',
        color: 'white',
      }}
    >
      <Container>
        <Box className="banner-inner" textAlign="center">
          <Typography variant="h3" color="light" fontWeight="bold">
            Explore All Amazing Places
          </Typography>
          <Typography variant="h6" color="light" my={2}>
            Ndoto Nzuri For Bookings & Experiences
          </Typography>
            <SearchBar />
        </Box>
      </Container>
    </Box>
  );
};

export default TopBanner;
