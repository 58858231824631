import React,  { useState } from 'react';
import { Box, Paper, InputBase, IconButton, SvgIcon, Divider, Button, Modal, Typography } from '@mui/material';
import { MdMap, MdCalendarMonth, MdPerson, MdSearch } from 'react-icons/md';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

const SearchBar = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [guestModalOpen, setGuestModalOpen] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);

  const handleGuestModalOpen = () => setGuestModalOpen(true);
  const handleGuestModalClose = () => setGuestModalOpen(false);


  return (
    <Paper
      component="form"
      sx={{ backgroundColor: 'white', p: '2px 8px', display: 'flex', alignItems: 'center', width: '100%', maxWidth: 700, mx: 'auto', borderRadius:20 }}
    >
      <SvgIcon><MdMap /></SvgIcon>
      <InputBase
        sx={{ ml: 1, flex: 1, p:1 }}
        placeholder="Where are you going?"
        inputProps={{ 'aria-label': 'Where are you going?' }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <SvgIcon><MdCalendarMonth /></SvgIcon>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker slots={{ field: SingleInputDateRangeField }} />
      </LocalizationProvider>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <SvgIcon sx={{fontWeight:'slim'}}><MdPerson /></SvgIcon>
      <Button variant="none" sx={{ flexGrow: 0.8, p:1, background:'white' }}>
       2 Guests
      </Button>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type="button" sx={{ p: 1, backgroundColor: 'blue', color:'white', }} aria-label="search">
        <MdSearch />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
