import React from "react";
import {
    Paper, 
    List,
    ListItem, 
    ListItemIcon, 
    ListItemText,
    Typography,
} from "@mui/material";
import { MdBed, MdWifi, MdLocationCity } from 'react-icons/md';
const FeaturesSection = () => {
  return (
    <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
        Amenities
        </Typography>
        <List>
        <ListItem>
            <ListItemIcon>
            <MdBed />
            </ListItemIcon>
            <ListItemText primary="1 Bedroom" />
        </ListItem>
        <ListItem>
            <ListItemIcon>
            <MdWifi />
            </ListItemIcon>
            <ListItemText primary="Free Wi-Fi" />
        </ListItem>
        <ListItem>
            <ListItemIcon>
            <MdLocationCity />
            </ListItemIcon>
            <ListItemText primary="Great Location" />
        </ListItem>
        </List>
    </Paper>

  );
};

export default FeaturesSection;
