import React from 'react'
import { Container, Box, Typography, Avatar } from '@mui/material'
import RegisterForm from '../../component/auth/RegisterForm'

const Register = () => {
  
  return (
    <Container 
      maxWidth="xs" 
      sx={{ 
        height: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}
    >
      <Box
        sx={{
          boxShadow: 3,
          p: 3,
          borderRadius: 2,
          backgroundColor: "#fff",
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center'
        }}
      >
        <Avatar
          src="./logo/logo.png"
          alt="Logo" 
          sx={{ 
            width: 100, // Adjust size as needed
            height: 100, // Adjust size as needed
            mb: 2 // Margin bottom to create space between avatar and form
          }} 
        />
        <Typography variant="h5" textAlign="center" gutterBottom>
          Sign up
        </Typography>
          <RegisterForm/>
          </Box>
    </Container>
  )
}

export default Register