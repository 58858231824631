import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const HowToBecomeAHost = () => {
  return (
    <section>
      <Container maxWidth="lg">
        <Box sx={{ py: 5 }}>
          {/* Section Title */}
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
              How To Become Ndoto Nzuri Host
            </Typography>
          </Box>

          {/* Step 1 */}
          <Box sx={{ mb: 5 }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h6">1. List Your Place</Typography>
                <Typography color="textSecondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum venenatis neque eget tristique. Sed at porta felis. Duis vitae malesuada lacus. Duis lobortis risus gravida leo pellentesque bibendum.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src="https://demo01.gethomey.io/wp-content/uploads/2020/10/become-a-host-1.jpg"
                  alt="List Your Place"
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mb: 5 }}>
            <Grid container spacing={4} alignItems="center" flexDirection={{ xs: 'column-reverse', md: 'row' }}>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src="https://demo01.gethomey.io/wp-content/uploads/2020/10/become-a-host-2.jpg"
                  alt="Welcome Your Guests"
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">2. Welcome Your Guests</Typography>
                <Typography color="textSecondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum venenatis neque eget tristique. Sed at porta felis. Duis vitae malesuada lacus. Duis lobortis risus gravida leo pellentesque bibendum.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Step 3 */}
          <Box>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h6">3. Earn Money</Typography>
                <Typography color="textSecondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum venenatis neque eget tristique. Sed at porta felis. Duis vitae malesuada lacus. Duis lobortis risus gravida leo pellentesque bibendum.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src="https://demo01.gethomey.io/wp-content/uploads/2020/10/become-a-host-3.jpg"
                  alt="Earn Money"
                  sx={{ width: '100%', height: 'auto' }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default HowToBecomeAHost;
