import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import { useAuthStore } from "../../store/auth";
import { register as authRegister } from '../../utils/auth';

function Register() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      password: '',
      password2: '',
      submit: null,
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        .max(255)
        .required('Name is required'),
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string()
        .min(7, 'Password must be at least 7 characters long')
        .max(255)
        .required('Password is required'),
      password2: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match') // Fixed comparison field
        .required('Confirm password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setIsLoading(true);
        const { error } = await authRegister(values.full_name, values.email, values.password, values.password2);

        if (error) {
          helpers.setErrors({ submit: error.message });
          helpers.setSubmitting(false);
          setIsLoading(false);
          return;
        }

        // Handle success (e.g., redirect to the dashboard)
        navigate('/');
      } catch (err) {
        console.error('Registration error:', err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}>
      <TextField
        error={Boolean(formik.touched.full_name && formik.errors.full_name)}
        fullWidth
        helperText={formik.touched.full_name && formik.errors.full_name}
        label="Full Name"
        margin="normal"
        name="full_name"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.full_name}
      />
      <TextField
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.email}
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />
      <TextField
        error={Boolean(formik.touched.password2 && formik.errors.password2)}
        fullWidth
        helperText={formik.touched.password2 && formik.errors.password2}
        label="Confirm Password"
        margin="normal"
        name="password2"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password2}
      />

      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>
            {formik.errors.submit}
          </FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={formik.isSubmitting || isLoading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {isLoading ? 'Registering...' : 'Register'}
        </Button>
      </Box>
    </form>
  );
}

export default Register;
