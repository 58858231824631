import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const ServiceBlocks = () => {
  return (
    <section>
      <Container maxWidth="lg">
        <Box sx={{ py: 5 }}>
          <Grid container spacing={4}>
            {/* Service Block 1 */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    mb: 2
                  }}
                />
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Why Choose Homey
                </Typography>
                <Typography color="textSecondary">
                  Its modern design and ease of use is just the tip of the iceberg. A feature-rich theme options panel offers great functionality to customize your site and adapt it to your business model.
                </Typography>
              </Box>
            </Grid>

            {/* Service Block 2 */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    mb: 2
                  }}
                />
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Booking Made Easy
                </Typography>
                <Typography color="textSecondary">
                  Homey is equipped with great tools needed to deliver a product that any rental business would be delighted to have an impressive and appealing online presence.
                </Typography>
              </Box>
            </Grid>

            {/* Service Block 3 */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                  border: '1px solid #ddd',
                  borderRadius: 2,
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    mb: 2
                  }}
                />
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Highly Customizable
                </Typography>
                <Typography color="textSecondary">
                  Homey is featured with every tool you’d need to deliver a professional website. It offers multiple possibilities of customization to make your design project unique and fascinating.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default ServiceBlocks;
