import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';

const ListYourSpaceSection = () => {
  return (
    <section>
      <Container
        maxWidth="lg"
        sx={{
          py: 4,
          bgcolor: '#f5f5f5', // Background color if needed
          textAlign: 'center'
        }}
      >
        <Box sx={{ mb: 4, py: 6 }}>
          <Typography variant="h2" component="h2" gutterBottom>
            List Your Space
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vitae pretium dolor, et facilisis lacus. Duis ultricies egestas vulputate
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Button
            variant="contained"
            color="primary"
            href="https://demo10.gethomey.io/become-a-host/"
            size="large"
          >
            Become a Host
          </Button>
        </Box>
        <Box sx={{ height: '70px' }} />
      </Container>
    </section>
  );
};

export default ListYourSpaceSection;
