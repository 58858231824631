import React from 'react';
import { Typography, Grid, Card, CardContent, Box } from '@mui/material';
import { MdHouse, MdGroup, MdBed, MdShower } from 'react-icons/md';

const AboutSection = () => (
  <>
    <Grid container spacing={2} mb={4}>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <CardContent>
            <Box display="flex" alignItems="center">
              <MdHouse size={24} />
              <Box ml={2}>
                <Typography variant="body2">Type</Typography>
                <Typography variant="h6" component="div" sx={{fontSize:'14px', fontWeight:700}}>
                  Entire Place / Apartment
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <CardContent>
            <Box display="flex" alignItems="center">
              <MdGroup size={24} />
              <Box ml={2}>
                <Typography variant="body2">Accommodation</Typography>
                <Typography variant="h6" component="div" sx={{fontSize:'14px', fontWeight:700}}>
                  4 Guests
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <CardContent>
            <Box display="flex" alignItems="center">
              <MdBed size={24} />
              <Box ml={2}>
                <Typography variant="body2">Bedrooms</Typography>
                <Typography variant="h6" component="div" sx={{fontSize:'14px', fontWeight:700}}>
                  2 Bedrooms / 2 Beds
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card variant="outlined">
          <CardContent>
            <Box display="flex" alignItems="center">
              <MdShower size={24} />
              <Box ml={2}>
                <Typography variant="body2">Bathrooms</Typography>
                <Typography variant="h6" component="div" sx={{fontSize:'14px', fontWeight:700}}>
                  2 Full
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

    <Box mb={4}>
      <Typography variant="h4" gutterBottom>
        About this listing
      </Typography>
      <Typography variant="body1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi est quam, volutpat et arcu eu, pharetra congue augue. Integer vel nibh eu eros interdum commodo. Vivamus finibus fringilla libero, id consectetur purus sollicitudin vel. Proin dapibus ante et pharetra luctus. Ut lacinia ante ut nunc pellentesque auctor. Proin laoreet erat sed ornare molestie. Fusce vehicula ut nulla facilisis vulputate. Quisque vel purus ac lectus tempus viverra. Maecenas at sem at erat pellentesque hendrerit nec in massa. Vestibulum nec lacinia dui, a congue ex. Vivamus ac ultricies mauris. Suspendisse commodo tempus suscipit. Nunc malesuada eu tortor in hendrerit.
      </Typography>
    </Box>
  </>
);

export default AboutSection;
