import React, { useState } from 'react';
import { ImageList, ImageListItem, Box } from '@mui/material';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const images = [
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/16-1-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/16-1.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/15-1-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/15-1.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/16-5-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/16-5.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10-1-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10-1.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/12-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/12.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10-3-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10-3.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/00_bathroom-2-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/00_bathroom-2.jpg',
  },
  {
    src: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/00_bathroom-1-750x500.jpg',
    full: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/00_bathroom-1.jpg',
  },
];

const GallerySection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <section className="detail-property-page-header-area detail-property-page-header-area-v4">
      <Box className="top-gallery-section" sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
        <ImageList sx={{ width: '100%', maxWidth: 1200 }} cols={3} gap={8}>
          {images.map((item, index) => (
            <ImageListItem key={index} sx={{ cursor: 'pointer' }} onClick={() => handleClick(index)}>
              <img
                src={`${item.src}`}
                alt=""
                loading="lazy"
                style={{ width: '100%', height: 'auto', borderRadius: 4 }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map(image => ({ src: image.full }))}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </section>
  );
};

export default GallerySection;
