import React from "react";
import { Container, Grid, Typography, Card, CardActionArea, CardMedia, Box } from "@mui/material";
import { Link } from "react-router-dom"; // Use React Router for navigation if needed

const TrendingDestinations = () => {
  const destinations = [
    {
      title: "Jinja",
      imageUrl: "https://demo01.gethomey.io/wp-content/uploads/2018/10/los-angeles-2-555x360.png",
      link: "https://demo01.gethomey.io/city/los-angeles/",
    },
    {
      title: "Nairobi",
      imageUrl: "https://demo01.gethomey.io/wp-content/uploads/2018/10/miami-beach-3-555x360.png",
      link: "https://demo01.gethomey.io/city/miami/",
    },
    {
      title: "New York",
      imageUrl: "https://demo01.gethomey.io/wp-content/uploads/2018/10/new-york-2-555x360.png",
      link: "https://demo01.gethomey.io/city/new-york/",
    },
    {
      title: "Fort Portal",
      imageUrl: "https://demo01.gethomey.io/wp-content/uploads/2018/10/san-fracisco-2-555x360.jpg",
      link: "https://demo01.gethomey.io/city/san-francisco/",
    },
  ];

  return (
    <section className="bg-white">
      <Container maxWidth="xl" sx={{ py: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
              Trending Destinations
            </Typography>
            <Typography variant="body1" color="textSecondary" textAlign="center" gutterBottom>
              Discover the most sought-after places to visit right now.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 2 }}>
          {destinations.map((destination, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <CardActionArea component={Link} to={destination.link}>
                  <CardMedia
                    component="img"
                    alt={destination.title}
                    height="260"
                    image={destination.imageUrl}
                    title={destination.title}
                  />
                  <Box 
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "rgba(0, 0, 0, 0.4)", // Optional: for dark overlay
                      color: "white", // Optional: for white text
                      p: 2,
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {destination.title}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

export default TrendingDestinations;
