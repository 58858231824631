import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Breadcrumbs, 
  Link, 
  Typography, 
  Rating, 
  IconButton, 
  Box, 
  Paper,
  Button,
  Divider
} from '@mui/material';
import { 
  MdLocationOn, 
  MdPrint, 
  MdFavorite 
} from 'react-icons/md';
import AboutSection from '../room/AboutSection';
import DetailsSection from '../room/DetailsSection';
import FeaturesSection from '../room/FeaturesSection';
import MapSection from '../room/MapSection';
import RulesSection from '../room/RulesSection';
import HostSection from '../room/HostSection';
import GallerySection from '../room/GallerySection';

const DetailStay = () => {
  const { id } = useParams(); // Get the id from the route
  const [property, setProperty] = useState(null);

  useEffect(() => {
    // Fetch the property details based on the id
    const fetchProperty = async () => {
      try {
        // const response = await fetch(`/api/properties/${id}`);
        const response = await fetch(`/stays/details/${id}`);
        const data = await response.json();
        setProperty(data);
      } catch (error) {
        console.error('Error fetching property data:', error);
      }
    };

    fetchProperty();
  }, [id]);

  // If property data is not yet loaded, show a loading state
  if (!property) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <GallerySection property={property}/>
    
      <Box className="main-content-area detail-property-page detail-property-page-v1" mb={20}>
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Grid container spacing={4}>
            {/* Left Column - Property Details */}
            <Grid item xs={12} md={8}>
              <Box>
                <Box sx={{ mb: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="https://demo01.gethomey.io/">
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" href="https://demo01.gethomey.io/type/apartment/">
                      Apartment
                    </Link>
                    <Typography color="text.primary">Georgeus Apartment</Typography>
                  </Breadcrumbs>
                </Box>
                <Typography variant="h4" component="h1" gutterBottom>
                  {property.name}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <Rating name="read-only" value={3} readOnly />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    3 - <Link href="https://demo01.gethomey.io/listing/georgeus-apartment/#reviews-section">1 Reviews</Link>
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                  <MdLocationOn color="primary" />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {property.address}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton aria-label="save">
                    <MdFavorite />
                  </IconButton>
                  <IconButton aria-label="print">
                    <MdPrint />
                  </IconButton>
                  
                </Box>
              </Box>
              <Divider />
              <AboutSection property={property} />
              <Divider />
              <DetailsSection property={property} />
              <Divider />
              <FeaturesSection property={property} />
              <Divider />
              <MapSection property={property} />
              <Divider />
              <RulesSection property={property} />
              <Divider />
              <HostSection property={property} />
            </Grid>

            {/* Right Column - Booking Section */}
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                  Book Now
                </Typography>
                <Typography variant="h6" gutterBottom>
                ${property.price} / night
                </Typography>
                <Button variant="contained" color="primary" fullWidth>
                  Check Availability
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DetailStay;
