import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import TopBanner from "../../component/TopBanner";
import TrendingDestinations from "../../component/TrendingDestinations";
import ModernApartmentSection from "../../component/ModernApartmentSection";
import UniqueExperiences from "../../component/experiences/UniqueExperiences";
import FeaturedHomes from "../../component/stays/FeaturedHomes";
import CategorySection from "../../component/CategorySection";

const Home = () => {
  return (
    <>
      <TopBanner />

      <Container maxWidth="xl" sx={{py:2}}>
        <Container maxWidth="xl" sx={{mb: "25px"}}>
          <Grid container spacing={2} px={1} py={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Our Featured Homes
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Handpicked stays offering comfort and style.
              </Typography>
            </Grid>
          </Grid>
          <FeaturedHomes />
        </Container>

        <Container maxWidth="xl">
          <TrendingDestinations />
        </Container>
      </Container>

      <ModernApartmentSection />

      <Container maxWidth="xl" sx={{py:2}}>
        <Container 
          maxWidth="xl"
          sx={{
            px: { xs: 1, sm: 2, md: 3 },
            py: { xs: 1, sm: 2, md: 3 },
            width: '100%',
          }}
        >
          <CategorySection />
        </Container>
      
        <Container maxWidth="xl" sx={{mb:8}}>
          <Grid container px={1} py={2}>
            <Grid item xs={12}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Discover our top unique experiences.
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Unforgettable experiences await.
              </Typography>
            </Grid>
          </Grid>
          <UniqueExperiences />
        </Container>
      </Container>
    </>
  );
};

export default Home;
