import React from "react";
import { Container, Box, Typography } from "@mui/material";
import LoginForm from "../../component/auth/LoginForm";

const Login = () => {
  return (
    <Container 
      maxWidth="xs" 
      sx={{ 
        height: '100vh', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}
    >
      <Box
        sx={{
          boxShadow: 3,
          p: 3,
          borderRadius: 2,
          backgroundColor: "#fff",
          width: '100%',
          maxWidth: '400px', // Adjust the maxWidth if needed
        }}
      >
        <Typography variant="h5" textAlign="center" gutterBottom>
          Sign in
        </Typography>
        <LoginForm />
      </Box>
    </Container>
  );
};

export default Login;
