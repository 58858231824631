import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

const ModernApartmentSection = () => {
  return (
    <Box
      sx={{
        backgroundImage: "url('/img/hotel1.jpg')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        py: 10,
      }}
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center">
          <Box
            sx={{
              width: '100%',
              maxWidth: { lg: '50%' },
              bgcolor: 'rgba(255, 255, 255, 0.89)',
              py: 6,
              px: 4,
              my: 4,
              boxShadow: 3,
              textAlign: 'center',
              borderRadius: 2,
            }}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Modern Apartment
            </Typography>
            <Typography variant="h6" color="textSecondary" gutterBottom>
              $79 at night
            </Typography>
            <Typography variant="body1" gutterBottom>
              I am text block. Click edit button to change this text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mt: 4 }}
            >
              Book Now
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ModernApartmentSection;
