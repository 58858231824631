import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import Colors from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import UserMenu from "./UserMenu";
import RegionCurrecy from "../region/RegionCurrecy";
import { MdLanguage } from "react-icons/md";

const TopNav = () => {
  const navigate = useNavigate();
  const bHost = () => {
    navigate("/become-host");
  };
  const Stay = () => {
    navigate("/stays");
  };
  const Experience = () => {
    navigate("/experiences");
  };
  const [open, setOpen] = useState(false);
  const handleRegionCurrecyOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: 0,
        borderBottom: `solid 0.1px ${Colors.border}`
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          padding: {
            lg: "10px 25px",
            md: "10px 15px",
            xs: "8px 6px",
          },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Link to="/">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="./logo/logo.png"
                height="38"
                className="d-inline-block align-top"
                alt="ndoto"
              />
            </Box>
          </Link>
        </Box>
        

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flexGrow: 1, display: { md: "flex", sm: "none", xs: "none" } }}>
            <Button
              onClick={Stay}
              sx={{
                mx: 3,
                px: 2,
                color: Colors.black,
                display: { md: "block" },
                borderColor: Colors.border,
                borderRadius: 30,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Stays
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1, display: { md: "flex", sm: "none", xs: "none" } }}>
            <Button
              onClick={Experience}
              sx={{
                mx: 3,
                px: 2,
                color: Colors.black,
                display: { md: "block" },
                borderColor: Colors.border,
                borderRadius: 30,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Experiences
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, display: { md: "flex", sm: "none", xs: "none" } }}>
            <Button
              onClick={bHost}
              sx={{
                mx: 3,
                px: 2,
                color: Colors.black,
                display: { md: "block" },
                borderColor: Colors.border,
                borderRadius: 30,
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              Become a Host
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1, display: { md: "flex", sm: "none", xs: "none" } }}>
            <IconButton
              onClick={handleRegionCurrecyOpen}
              sx={{
                mx: 5,
                color: Colors.black,
                display: "block",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              <MdLanguage />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <UserMenu />
          </Box>
        </Box>
        <RegionCurrecy 
          open = {open}
          handleClose={handleClose}
        />
      </Container>
    </Paper>
  );
};

export default TopNav;
