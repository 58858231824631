import React from "react";
import { Container } from "@mui/material";
import Stays from "../../component/stays/Stays";

const StaysIndex = () => {
  return (
    <>
        <Container maxWidth="xl" sx={{py:5, mb: "25px"}}>
            <Stays />
        </Container>
    </>
  );
};

export default StaysIndex;
