import React, { forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import Colors from "../../styles/theme";
import { MdClose } from "react-icons/md";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RegionCurrecy = ({ open, handleClose }) => {
  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogActions>
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{
            backgroundColor: Colors.primary,
            color: Colors.white,
          }}
        >
          <MdClose />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default RegionCurrecy;
