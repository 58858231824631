import React from "react";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Colors from "../../styles/theme";
import { MdHelp, MdLogin, MdLogout, MdOutlineVerifiedUser, MdPerson } from "react-icons/md";
import { SiGnuprivacyguard } from "react-icons/si";
import { useAuthStore } from "../../store/auth";

const UserMenu = () => {
  const [isLoggedIn, user] = useAuthStore((state)=>[state.isLoggedIn, state.user]);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const uRegister = () => {
    navigate("/register");
  };
  const uLogin = () => {
    navigate("/login");
  };
  const profile = () => {
    navigate("/user-profile");
  };
  const bHost = () => {
    navigate("/become-host");
  };
  const help = () => {
    navigate("/help");
  };
  const logout = () => {
    navigate("/logout");
  };
  return (
    <>
      <Tooltip title="Open settings">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{
            p: 0.5,
            border: `solid 2px ${Colors.border}`,
          }}
        >
          {isLoggedIn() ? (
            <Avatar sx={{width:25, height:25}} />
          ):(
            <Avatar sx={{width:25, height:25}} alt={user.username} src={user.image} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElUser}
        id="account-menu"
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 45,
              height: 45,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
          {isLoggedIn() ? (
            <>
              <MenuItem to="/" onClick={uRegister}>
                <ListItemIcon>
                  <SiGnuprivacyguard fontSize="small" />
                </ListItemIcon>
                Sign up
              </MenuItem>
              <MenuItem to="/" onClick={uLogin}>
                <ListItemIcon>
                  <MdLogin fontSize="small" />
                </ListItemIcon>
                Sign in
              </MenuItem>
            </>
          ):(
            <>
              <MenuItem to="/" onClick={profile}>
                <ListItemIcon>
                  <MdPerson fontSize="small" />
                </ListItemIcon>
                My Profile
              </MenuItem>

              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <MdLogout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </>
          )}
          
        <Divider />
        <MenuItem onClick={bHost}>
          <ListItemIcon>
            <MdOutlineVerifiedUser fontSize="small" />
          </ListItemIcon>
          Become a Host
        </MenuItem>
        <MenuItem onClick={help}>
          <ListItemIcon>
            <MdHelp fontSize="small" />
          </ListItemIcon>
          Help
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
