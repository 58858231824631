import React from 'react';
import { Typography, Box, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { MdArrowForward } from 'react-icons/md';

const RulesSection = () => {
  return (
    <>
      <Box className="block">
        <Box className="block-section">
          <Box className="block-body">
            <Box className="block-left">
              <Typography variant="h6" gutterBottom>
                Terms &amp; Rules
              </Typography>
            </Box>
            <Box className="block-right">
              <List>
                <ListItem>
                  <ListItemIcon>
                    <MdArrowForward />
                  </ListItemIcon>
                  <ListItemText
                    primary="Smoking allowed:"
                    secondary={<strong>No</strong>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MdArrowForward />
                  </ListItemIcon>
                  <ListItemText
                    primary="Pets allowed:"
                    secondary={<strong>No</strong>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MdArrowForward />
                  </ListItemIcon>
                  <ListItemText
                    primary="Party allowed:"
                    secondary={<strong>No</strong>}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <MdArrowForward />
                  </ListItemIcon>
                  <ListItemText
                    primary="Children allowed:"
                    secondary={<strong>Yes</strong>}
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default RulesSection;
