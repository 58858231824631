import React, { useState } from "react"; // Added useState import
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import { useAuthStore } from "../../store/auth";
import { login as authLogin } from '../../utils/auth';

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string()
        .min(7)
        .max(255)
        .required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setIsLoading(true); // Set loading state before API call

        // Call the login action
        const { error } = await authLogin(values.email, values.password); // Use custom login function

        if (error) {
          helpers.setErrors({ submit: error.message });
          helpers.setSubmitting(false);
          setIsLoading(false); // Reset loading state on error
          return;
        }

        // Handle success (e.g., redirect to the dashboard)
        navigate('/');
      } catch (err) {
        console.error('Login error:', err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setIsLoading(false); // Reset loading state on error
      }
    },
  });

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}>
      <TextField
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        label="Email Address"
        margin="normal"
        name="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="email"
        value={formik.values.email}
      />
      <TextField
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        label="Password"
        margin="normal"
        name="password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
      />
      
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>
            {formik.errors.submit}
          </FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={formik.isSubmitting || isLoading} // Disable button while submitting or loading
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        >
          {isLoading ? 'Signing In...' : 'Sign In'} {/* Display loading text */}
        </Button>
      </Box>
    </form>
  );
}

export default Login;
