import { Grid } from "@mui/material";
import Slider from "react-slick";
import ExperienceCard from "./ExperienceCard";

const UniqueExperiences = () => {
  
  const experiences = [
    {
      id: 1,
      images:  "./experience/27.jpeg",
      host: "Jon Snow",
      email: "jonsnow@gmail.com",
      age: 35,
      phone: "(665)121-5454",
      address: "0912 Won Street, Alabama, SY 10001",
      date_range: "New York",
      cost: "10001",
      registrarId: 123512,
    },
    {
      id: 2,
      images: "./experience/02.webp",
      host: "Cersei Lannister",
      email: "cerseilannister@gmail.com",
      age: 42,
      phone: "(421)314-2288",
      address: "1234 Main Street, New York, NY 10001",
      date_range: "New York",
      cost: "13151",
      registrarId: 123512,
    },
    {
      id: 3,
      images:  "./experience/03.webp",
      host: "Jaime Lannister",
      email: "jaimelannister@gmail.com",
      age: 45,
      phone: "(422)982-6739",
      address: "3333 Want Blvd, Estanza, NAY 42125",
      date_range: "New York",
      cost: "87281",
      registrarId: 4132513,
    },
    {
      id: 4,
      images: "./experience/04.webp",
      host: "Anya Stark",
      email: "anyastark@gmail.com",
      age: 16,
      phone: "(921)425-6742",
      address: "1514 Main Street, New York, NY 22298",
      date_range: "New York",
      cost: "15551",
      registrarId: 123512,
    },
    {
      id: 5,
      images:  "./experience/05.webp",
      host: "Daenerys Targaryen",
      email: "daenerystargaryen@gmail.com",
      age: 31,
      phone: "(421)445-1189",
      address: "11122 Welping Ave, Tenting, CD 21321",
      date_range: "Tenting",
      cost: "14215",
      registrarId: 123512,
    },
    {
      id: 6,
      images:  "./experience/06.webp",
      host: "Ever Melisandre",
      email: "evermelisandre@gmail.com",
      age: 150,
      phone: "(232)545-6483",
      address: "1234 Canvile Street, Esvazark, NY 10001",
      date_range: "Esvazark",
      cost: "10001",
      registrarId: 123512,
    },
    {
      id: 7,
      images:  "./experience/07.webp",
      host: "Ferrara Clifford",
      email: "ferraraclifford@gmail.com",
      age: 44,
      phone: "(543)124-0123",
      address: "22215 Super Street, Everting, ZO 515234",
      date_range: "Evertin",
      cost: "51523",
      registrarId: 123512,
    },
    {
      id: 11,
      images:  "./experience/11.jpeg",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 12,
      images:  "./experience/12.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 13,
      images:  "./experience/13.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 14,
      images:  "./experience/14.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 15,
      images:  "./experience/15.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 16,
      images:  "./experience/16.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 17,
      images:  "./experience/17.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 18,
      images:  "./experience/18.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 19,
      images:  "./experience/19.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 20,
      images:  "./experience/20.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 21,
      images:  "./experience/21.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 22,
      images:  "./experience/22.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 23,
      images:  "./experience/23.webp",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 8,
      images:  "./experience/25.jpeg",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 9,
      images:  "./experience/26.jpeg",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 24,
      images:  "./experience/28.jpeg",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    },
    {
      id: 25,
      images:  "./experience/30.jpeg",
      host: "Rossini Frances",
      email: "rossinifrances@gmail.com",
      age: 36,
      phone: "(222)444-5555",
      address: "4123 Ever Blvd, Wentington, AD 142213",
      date_range: "Esteras",
      cost: "44215",
      registrarId: 512315,
    }
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {experiences.map((experience) => (
        <Grid key={experience.id} item xs={12} sm={6} md={3} lg={3}>
          <ExperienceCard experience={experience} />
        </Grid>
      ))}
    </Slider>
  );
};

export default UniqueExperiences;
