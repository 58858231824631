import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Checkbox, FormControlLabel, Container, Grid } from '@mui/material';

const BecomeAHostSection = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
  };

  return (
    <section>
      <Container maxWidth="lg" sx={{ my: 10, px: 2 }}>
        <Grid container spacing={4}>
          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h4" fontWeight="bold" align="center" gutterBottom>
                Become a Host
              </Typography>
              <Typography variant="h6" color="textSecondary" align="center">
                Start To Earning Money On Your Schedule
              </Typography>
            </Box>
          </Grid>
          
          {/* Form Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: 2 }}>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Register
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  name="username"
                  label="Username"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.username}
                  onChange={handleChange}
                />
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.password}
                  onChange={handleChange}
                />
                <TextField
                  name="confirmPassword"
                  label="Repeat Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="termsAccepted"
                      checked={formData.termsAccepted}
                      onChange={handleChange}
                      required
                    />
                  }
                  label={
                    <span>
                      I agree with your <a href="/become-a-host/">Terms & Conditions</a>
                    </span>
                  }
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Register
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default BecomeAHostSection;
