import React from 'react';
import { Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { MdDescription, MdGroup, MdBed, MdShower, MdCalendarToday, MdFingerprint, MdSquareFoot } from 'react-icons/md';


const DetailsSection = () => (
    <>
      <Typography variant="h4" gutterBottom>Details</Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <MdDescription />
          </ListItemIcon>
          <ListItemText primary="ID: 344" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdGroup />
          </ListItemIcon>
          <ListItemText primary="Guests: 4" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdBed />
          </ListItemIcon>
          <ListItemText primary="Bedrooms: 2" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdBed />
          </ListItemIcon>
          <ListItemText primary="Beds: 2" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdShower />
          </ListItemIcon>
          <ListItemText primary="Bathrooms: 2" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdCalendarToday />
          </ListItemIcon>
          <ListItemText primary="Check-in After: 10:00 AM" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdFingerprint />
          </ListItemIcon>
          <ListItemText primary="Type: Entire Place / Apartment" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MdSquareFoot />
          </ListItemIcon>
          <ListItemText primary="Size: 1500 SqFt" />
        </ListItem>
      </List>
    </>
);

export default DetailsSection;
