import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Home from './pages/home/Home'
import Stays from './pages/stays'
import StayDetails from './pages/stays/details'
import Experiences from './pages/experiences'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import TopNav from './component/navbars/TopNav';
import Footer from './component/navbars/Footer';
import Terms from './pages/about/Terms';
import Sitemap from './pages/about/Sitemap';
import Privacy from './pages/about/Privacy';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Host from './pages/host';
import Detail from './pages/rooms/details';
import Help from './pages/help';
import Profile from './pages/auth/Profile';
import Logout from './component/auth/Logout';

function App() {
  return (
    <BrowserRouter>
      <TopNav />
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route path="/stays" Component={Stays} />
        <Route path="/stays/details/:id" Component={StayDetails} />
        <Route path="/experiences" Component={Experiences} />
        <Route path="/proparty/details/:id" Component={Detail} />
        <Route path="/logout" Component={Logout} />
        <Route path="/login" Component={Login} />
        <Route path="/register" Component={Register} />
        <Route path="/terms" Component={Terms} />
        <Route path="/sitemap" Component={Sitemap} />
        <Route path="/privacy" Component={Privacy} />
        <Route path="/become-host" Component={Host} />
        <Route path="/help" Component={Help} />
        <Route path="/user-profile" Component={Profile} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
