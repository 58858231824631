import React from 'react'
import BecomeAHostSection from '../../component/BecomeAHostSection'
import ServiceBlocks from '../../component/ServiceBlocks';
import HowToBecomeAHost from '../../component/HowToBecomeAHost';
import KeepEverythingUnderControl from '../../component/KeepEverythingUnderControl';
import FAQs from '../../component/FAQs';
import ListYourSpaceSection from '../../component/ListYourSpaceSection';

const Host = () => {
  return (
    <>
      <BecomeAHostSection />
      <ServiceBlocks />
      <HowToBecomeAHost />
      <KeepEverythingUnderControl />
      <FAQs />
      <ListYourSpaceSection />
    </>
  );
};

export default Host