import React from 'react';
import { Box, Container, Typography, Grid, Card, CardActionArea, CardMedia, Button } from '@mui/material';

const CategorySection = () => {
  const places = [
    { title: 'Apartment', imageUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/39-2-555x262.jpg', link: 'https://demo01.gethomey.io/type/apartment/' },
    { title: 'Bed & Breakfast', imageUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/16-4-360x360.jpg', link: 'https://demo01.gethomey.io/type/bed-breakfast/' },
    { title: 'Condo', imageUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/33-360x360.jpg', link: 'https://demo01.gethomey.io/type/condo/' },
    { title: 'House', imageUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/10-7-360x360.jpg', link: 'https://demo01.gethomey.io/type/house/' },
    { title: 'Loft', imageUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/20-360x360.jpg', link: 'https://demo01.gethomey.io/type/loft/' },
    { title: 'Studio', imageUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/10/15-555x262.jpg', link: 'https://demo01.gethomey.io/type/studio/' },
  ];

  return (
    <Box sx={{ py: 10 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" textAlign="center">
          <Grid item xs={12}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Find a Place By Category
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {places.map((place, index) => (
            <Grid item xs={6} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <CardActionArea 
                  href={place.link}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      maxHeight: 150,
                      minHeight: 100,
                      objectFit: 'cover',
                    }}
                    image={place.imageUrl}
                    alt={place.title}
                  />
                  <Box 
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgba(0, 0, 0, 0.4)',
                      color: 'white',
                      p: { xs: 0.2, sm: 0 },
                      textAlign: 'center',
                      zIndex: 1,
                      '& h6': {
                        fontSize: { xs: '1rem', sm: '1.25rem' },
                        lineHeight: { xs: '1.2rem', sm: '1.5rem' },
                      },
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {place.title}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" href="#">
            Load More
          </Button>
        </Grid>
      </Container>
    </Box>
  );
};

export default CategorySection;
