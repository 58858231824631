import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

const KeepEverythingUnderControl = () => {
  return (
    <section>
      <Container maxWidth="lg">
        <Box sx={{ py: 10 }}>
          {/* Section Title */}
          <Box sx={{ mb: 8 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Keep Everything Under Control
            </Typography>
          </Box>

          {/* Content */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Box
                  component="img"
                  src="https://demo10.gethomey.io/wp-content/uploads/2019/04/icon-23.png"
                  alt="Listing Management"
                  sx={{ width: 60, height: 60, mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Listing Management
                </Typography>
                <Typography color="textSecondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum venenatis neque eget tristique. Sed at porta felis. Duis vitae malesuada lacus. Duis lobortis risus gravida leo pellentesque bibendum.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Box
                  component="img"
                  src="https://demo10.gethomey.io/wp-content/uploads/2019/04/icon-16.png"
                  alt="Booking System"
                  sx={{ width: 60, height: 60, mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Booking System
                </Typography>
                <Typography color="textSecondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum venenatis neque eget tristique. Sed at porta felis. Duis vitae malesuada lacus. Duis lobortis risus gravida leo pellentesque bibendum.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Box
                  component="img"
                  src="https://demo10.gethomey.io/wp-content/uploads/2019/04/icon-07.png"
                  alt="Prices and Fees"
                  sx={{ width: 60, height: 60, mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Prices and Fees
                </Typography>
                <Typography color="textSecondary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum venenatis neque eget tristique. Sed at porta felis. Duis vitae malesuada lacus. Duis lobortis risus gravida leo pellentesque bibendum.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default KeepEverythingUnderControl;
