import React from 'react'
import DetailStay from '../../component/stays/DetailStay';

const StayDetails = () => {
  return (
    <>
      <DetailStay />
    </>
  );
};

export default StayDetails