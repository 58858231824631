import React from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  FaDollarSign,
  FaFacebook,
  FaGlobeAfrica,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { TbCopyright } from "react-icons/tb";
import RegionCurrecy from "../region/RegionCurrecy";

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const handleRegionCurrecyOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Paper
      elevation={0}
      className="footer"
      sx={{
        position: "relative",
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: "auto", // This ensures it stays at the bottom of the page
        borderRadius: 0
      }}
    >
      <Container
        maxWidth="xl"
      >
        <Grid container spacing={3}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
        >
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                display: { md: "flex", sm: "block" },
                flexDirection: "row",
                alignItems: "ceter",
                justifyContent: "space-between",
                justifyItems: "center",
              }}
            >
              <MenuList
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MenuItem>
                    <ListItemIcon><TbCopyright /></ListItemIcon>
                    <ListItemText>
                        <Typography>
                            2023 ndoto, All rights reserved
                        </Typography>
                    </ListItemText>
                </MenuItem>
                </MenuList>
              <MenuList
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MenuItem>
                  <ListItemText>
                    <Link to="/about">About</Link>
                  </ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemText>
                    <Link to="/terms">Terms</Link>
                  </ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemText>
                    <Link to="/sitemap">Sitemap</Link>
                  </ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemText>
                    <Link to="/privacy">Privacy</Link>
                  </ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemText>
                    <Link to="/privacy">Contact</Link>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <Box>
              <MenuList
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <MenuItem onClick={handleRegionCurrecyOpen}>
                  <IconButton>
                    <FaGlobeAfrica /> English
                  </IconButton>
                </MenuItem>
                <MenuItem onClick={handleRegionCurrecyOpen}>
                  <IconButton>
                    <FaDollarSign /> UGx
                  </IconButton>
                </MenuItem>
              </MenuList>
            </Box>
          </Grid>
          <Grid item xs={3} md={3}>
            <MenuList
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <MenuItem>
                <ListItemIcon>
                  <FaFacebook />
                </ListItemIcon>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <FaTwitter />
                </ListItemIcon>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <FaInstagram />
                </ListItemIcon>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <FaLinkedin />
                </ListItemIcon>
              </MenuItem>
            </MenuList>
          </Grid>
        </Grid>
        <RegionCurrecy 
          open = {open}
          handleClose={handleClose}
        />
      </Container>
    </Paper>
  );
};

export default Footer;
