import React from 'react';
import { 
  Container, 
  Grid, 
  Breadcrumbs, 
  Link, 
  Typography, 
  Rating, 
  IconButton, 
  Box, 
  Paper,
  Button,
  Divider
} from '@mui/material';
import { 
  MdLocationOn, 
  MdPrint, 
  MdFavorite 
} from 'react-icons/md';
import FeaturesSection from './FeaturesSection';
import DetailsSection from './DetailsSection';
import AboutSection from './AboutSection';
import MapSection from './MapSection';
import RulesSection from './RulesSection';
import HostSection from './HostSection';

const DetailPropertyPage = () => {
  return (
    <Box className="main-content-area detail-property-page detail-property-page-v1" mb={20}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          {/* Left Column - Property Details */}
          <Grid item xs={12} md={8}>
            <Box>
              <Box sx={{ mb: 2 }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="https://demo01.gethomey.io/">
                    Home
                  </Link>
                  <Link underline="hover" color="inherit" href="https://demo01.gethomey.io/type/apartment/">
                    Apartment
                  </Link>
                  <Typography color="text.primary">Georgeus Apartment</Typography>
                </Breadcrumbs>
              </Box>
              <Typography variant="h4" component="h1" gutterBottom>
                Georgeus Apartment
              </Typography>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Rating name="read-only" value={3} readOnly />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  3 - <Link href="https://demo01.gethomey.io/listing/georgeus-apartment/#reviews-section">1 Reviews</Link>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <MdLocationOn color="primary" />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  1499 34th Ave, San Francisco, CA 94122
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <IconButton aria-label="save">
                  <MdFavorite />
                </IconButton>
                <IconButton aria-label="print">
                  <MdPrint />
                </IconButton>
                
              </Box>
            </Box>
            <Divider />
            <AboutSection />
            <Divider />
            <DetailsSection />
            <Divider />
            <FeaturesSection />
            <Divider />
            <MapSection />
            <Divider />
            <RulesSection />
            <Divider />
            <HostSection />
          </Grid>

          {/* Right Column - Booking Section */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h5" gutterBottom>
                Book Now
              </Typography>
              <Typography variant="h6" gutterBottom>
                $120 / night
              </Typography>
              <Button variant="contained" color="primary" fullWidth>
                Check Availability
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DetailPropertyPage;
