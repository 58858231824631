import React from "react";
import { Container } from "@mui/material";
import Experiences from "../../component/experiences/Experiences";

const ExperiencesIndex = () => {
  return (
    <>
        <Container maxWidth="xl" sx={{py:5, mb: "25px"}}>
            <Experiences />
        </Container>
    </>
  );
};

export default ExperiencesIndex;
