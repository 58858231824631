import React from 'react';
import { Box } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Set up the custom icon for the marker
const customIcon = new L.Icon({
  iconUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/11/1-pin-apartment.png',
  iconRetinaUrl: 'https://demo01.gethomey.io/wp-content/uploads/2018/11/1-pin-apartment@2x.png',
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

const MapSection = () => {
  // Coordinates for the map center
  const position = [37.7591329, -122.49295799999999];

  return (
    <Box className="map-section-map" sx={{ height: '400px', width: '100%' }}>
        <MapContainer center={position} zoom={14} style={{ height: '100%', width: '100%' }}>
            <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={customIcon}>
            <Popup>
                <span>Custom Location</span>
            </Popup>
            </Marker>
        </MapContainer>
    </Box>
  );
};

export default MapSection;
