import React from 'react';
import { Typography, Box, Avatar, List, ListItem, ListItemText, Button } from '@mui/material';
import { MdLocationOn, MdCancel } from 'react-icons/md';

const HostSection = () => {
  return (
    <>
      <Box className="block">
        <Box className="block-head" sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt="Host Image"
            src="https://demo01.gethomey.io/wp-content/uploads/2018/10/HomeyHost21-1-150x150.jpg"
            sx={{ width: 70, height: 70, borderRadius: '50%', mr: 2 }}
          />
          <Box>
            <Typography variant="h6" gutterBottom>
              Hosted by <span>Emily Gilbert</span>
            </Typography>
            <List className="profile-host-info" sx={{ p: 0, display: 'flex', alignItems: 'center' }}>
              <ListItem sx={{ p: 0 }}>
                <MdLocationOn />
                <ListItemText primary="United States" sx={{ ml: 1 }} />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box className="block-body">
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            <Box sx={{ flex: '1 1 100%', md: '1 1 33%' }}>
              <Typography variant="subtitle1" gutterBottom>
                Languages
              </Typography>
              <Typography>English, Spanish, German</Typography>
            </Box>
            <Box sx={{ flex: '1 1 100%', md: '1 1 33%' }}>
              <Typography variant="subtitle1" gutterBottom>
                Profile Status
              </Typography>
              <Typography color="error">
                <MdCancel /> Not Verified
              </Typography>
            </Box>
          </Box>
          <Box className="host-section-buttons" sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={() => alert('Contact the host')}
            >
              Contact the host
            </Button>
            <Button
              variant="outlined"
              color="primary"
              href="https://demo01.gethomey.io/host/homeyhost21/"
            >
              View Profile
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HostSection;
