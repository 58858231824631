import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Box, Typography } from '@mui/material';

const StayCard = ({ property }) => {
  const link = '/stays/details/';
  const generateDetailsLink = () => {
    return `${link}${property.id}?adults=1&children=0&infants=0&pets=0&search_mode=flex_destinations_search`;
  };
  return (
    <Paper elevation={0} sx={{ padding: 1 }}>
      <Link to={generateDetailsLink()} style={{ textDecoration: 'none' }}>
        <Box
          component="img"
          sx={{
            height: 255,
            display: "block",
            maxWidth: 400,
            overflow: "hidden",
            width: "100%",
            zIndex: 1,
            borderRadius: 1,
          }}
          src={property.images}
          alt={property.label}
        />
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontSize: "15px",
            pt: 1.5,
            fontWeight: 600,
            lineHeight: "16px",
            color: "rgb(34, 34, 34)",
          }}
        >
          {property.address}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Host: {property.host}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {property.date_range}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <strong>${property.cost}</strong> per night
        </Typography>
      </Link>
    </Paper>
  );
};

export default StayCard;
